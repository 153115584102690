import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  margin: 80px auto 50px;
  align-items: center;
  justify-content: space-evenly;
  background: url(${(props) => (props.avatar ? props.avatar : null)});
  background-position: center center;
  background-repeat: no-repeat;
  color: white;
  padding: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BannerPart1 = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: center;
  order: 1;
  padding-left: 40px;

  > h1 {
    font-size: 55px;
  }
  > p {
    font-size: 24px;
  }

  > h3 {
    font-size: 40px;
  }

  > div > p {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    > h1 {
      font-size: 20px;
    }
    > h3 {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    order: 2;
  }
`;

export const BannerPart2 = styled.div`
  flex: 0.5;
  margin-left: 20px;
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .right-img {
    width: 100%;
    padding-right: 15px;
    @media screen and (max-width: 420px) {
      width: 100%;
    }
  }
  button {
    margin-top: 20px;
    /* margin-left: 230px; */
    /* justify-content: center; */
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 16rem;
    height: 56px;
    top: 0;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
      height: 35px;
    }
  }

  > div {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    order: 1;
  }
`;
