import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

import {
  ContentWrapper,
  BannerPart1,
  BannerPart2,
} from "./zoominfoHeader.style";
import bg from "../../../common/assets/image/saasModern/zoominfo/bg.jpg";

const WebinarHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWebinarPageHeaderImage {
        nodes {
          img {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiWebinarPageHeaderImage.nodes[0];
  return (
    <ContentWrapper avatar={bg} id="head">
      <BannerPart1>
        <h1>
          Hands-On Training:
          <br /> Mastering Lead Generation with Clodura.AI
        </h1>
        <h3>Event Details:</h3>
        <p>
          Date: Every Thursday
          <br /> Time: 9:30 PM(IST)
        </p>
      </BannerPart1>
      <BannerPart2>
        <img className="right-img" src={`${img.img.localFile.publicURL}`} />

        <Link
          // href="https://zoom.us/webinar/register/2016886211418/WN_w5EMha-KR7GuVKAUgyCxEA"
          target={"_blank"}
        >
          <button>Register Now</button>
        </Link>
      </BannerPart2>
    </ContentWrapper>
  );
};

export default WebinarHeader;
