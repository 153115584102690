import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ZoomInfoHeaderWrapper from "./zoominfoHeader.style";
import { Link } from "gatsby";

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWebinarPageImage {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
          img2 {
            localFile {
              publicURL
            }
          }
          img3 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const data = ZoominfoPageData.allStrapiWebinarPageImage.nodes[0];

  return (
    <>
      <ZoomInfoHeaderWrapper>
        <div className="feat">
          <h1>About the Speaker</h1>
          <img
            className="right-img"
            src={`${data.img1.localFile.publicURL}`}
            alt={"webinar-1"}
          />

          <p>
            Our speaker for this hands-on training session is Kapil Khangaonkar,
            the CEO & Founder of Clodura.AI. Kapil has more than 17 years of
            experience in sales and marketing, having worked in various
            leadership roles for companies such as Calsoft, Xerces, Uniken, and
            BMC Software.
          </p>
          <p>
            Kapil founded Clodura.AI to address the challenges he faced
            connecting with more customers and tapping into potential revenue
            opportunities. He saw teams using inefficient and time-consuming
            processes that wasted a lot of time and budget finding leads,
            identifying potential opportunities, doing sales research, and
            creating personalized outreach campaigns.
          </p>
          <p>
            Together with his growing team, Kapil has developed an AI-powered
            sales data and engagement platform that does the major heavy-lifting
            to ensure sales professionals never miss any potential opportunities
            and generate more meetings. Kapil has helped countless businesses
            transform their sales strategies and achieve unprecedented success.
          </p>
          <p>
            Join Kapil in this hands-on training session and learn from his
            extensive experience and knowledge in the sales and marketing field
          </p>
        </div>
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <img
          className="right-img left"
          src={`${data.img2.localFile.publicURL}`}
          alt={"webinar-2"}
        />
        <div className="text">
          <h1>Who Should Attend</h1>
          <p> This hands-on training session is ideal for:</p>

          <p>
            <li>
              Sales professionals who want to boost their sales pipeline's
              efficiency
            </li>
          </p>

          <p>
            <li>
              Marketers who want to improve their lead generation strategies
            </li>
          </p>

          <p>
            <li>Business owners who want to increase their ROI and sales</li>
          </p>
        </div>
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <div className="text">
          <h1>How to Register?</h1>
          <p>
            To register for the webinar, simply fill out the registration form.
            Once you've registered, we'll send you a confirmation email with all
            the details you need to join the webinar on
            <b> Every Thursday at 9:30 PM IST</b>
            .
            <br />
            <br />
            Don't miss out on this opportunity to learn from Kapil Khangaonkar,
            CEO & Founder of Clodura.AI and discover how Clodura.AI can help you
            Master Lead Generation.
          </p>
        </div>

        <img
          className="right-img"
          src={`${data.img3.localFile.publicURL}`}
          alt={"webinar-3"}
        />
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <Link
          // href="https://zoom.us/webinar/register/2016886211418/WN_w5EMha-KR7GuVKAUgyCxEA"
          target={"_blank"}
        >
          <button>Register Now</button>
        </Link>
      </ZoomInfoHeaderWrapper>
    </>
  );
};

export default ZoomInfoHeader;
