import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import Footer from 'containers/AgencyModern/Footer';
import ZoomInfoHeader from '../containers/Custom/Webinar/MainComHeading';
import WebinarHeader from '../containers/Custom/Webinar';
import Seo from 'components/seo';
import Directory from '../containers/Directory';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';

const Webinar = () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title="Hands-On Training: Master Lead Generation with Clodura.AI"
          description="Learn how to streamline your lead generation process and boost your sales pipeline's efficiency with Clodura.AI. Register now for our hands-on training session with CEO & Founder, Kapil Khangaonkar."
          robots="index, follow"
          canonical={`https://www.clodura.ai/webinar/`}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <WebinarHeader />
          <ZoomInfoHeader />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default Webinar;
